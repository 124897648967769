/*
 * 업무구분: 상품
 * 화 면 명: MSPFS201M
 * 화면설명: 펀드기본정보조회4665
 * 작 성 일: 2023.01.16
 * 작 성 자: 이지수
 */
<template>
  <ur-page-container class="fts" :show-title="false" title="펀드기본정보조회" :topButton="true">
    <!-- header start -->
    <fs-header
      ref="fsHeader"
      :propObj="pHeaderObj"
    ></fs-header>
    <!-- header end -->

    <!-- .fts-main -->
    <main class="fts-main">
      <ur-box-container direction="column" alignV="start">
        <div class="input-top">
          <div class="left gap40">
            <div class="wrap-input row">
              <label class="emphasis"> 펀드코드 </label>
              <mo-text-field
                type="number"
                maxlength="6"
                v-model="ds_search.fndcd"
                @keyup="fn_fnd_cd_onchar"
                class="w130"
              />
              <mo-button class="w61" @click="fn_OpenPopup">펀드코드</mo-button>
              <mo-text-field v-model="ds_search.fndnm" class="input-long w350" disabled />
              <mo-badge class="badge-fund-type" v-if="ds_list.coll_gb != ''">
                {{ds_list.coll_gb}}
              </mo-badge>
              <!-- 공모펀드인지 사모펀드인지에 따라 바뀌어 보여지는 Badge
                <mo-badge class="badge-fund-type" text="사모펀드" /> -->
            </div>
          </div>
          <div class="right">
            <div class="wrap-button row">
              <mo-button class="clear" @click="fn_init"> 초기화 </mo-button>
              <mo-button primary class="inquiry" :disabled="ds_search.fndcd == ''" @click="fn_searchList"> 조회 </mo-button>
            </div>
          </div>
        </div>
      </ur-box-container>

      <ur-box-container direction="column" alignV="start">
        <div class="wrap-table-title row">
          <h2>기본정보 및 분류정보</h2>
          <div class="wrap-table-badge row"  v-if="ds_list.fund_state != ''">
            <span class="badge-title">판매상태</span>
            <mo-badge class="badge-type possible">
              {{ds_list.fund_state}}
            </mo-badge>
          </div>
        </div>
        <div class="wrap-table">
          <table class="table col-type type02 pl10">
            <tbody>
              <tr>
                <th>펀드명</th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field
                      class="w200  ico-increase"
                      v-model="ds_list.fund_nm"
                      :class="{'input-increase': isIncrease, 'ico-increase': ds_list.fund_nm != ''}"
                      @click="inputIncrease"
                      readonly
                    />
                  </div>
                </td>
                <th>펀드유형3</th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="w150" v-model="ds_list.fund_tp3" disabled />
                  </div>
                </td>
                <th>펀드설정일</th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field v-model="ds_list.fund_orign_setdt" class="aC" disabled />
                  </div>
                </td>
                <th>펀드만기일</th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field v-model="ds_list.fund_exp_dt" class="aC" disabled />
                  </div>
                </td>
              </tr>
              <tr>
                <th>운용사명</th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="w200" v-model="ds_list.invst_comp_nm" disabled />
                  </div>
                </td>
                <th>펀드유형6</th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="w150" v-model="ds_list.fund_tp6" disabled />
                  </div>
                </td>
                <th>판매개시일</th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field v-model="ds_list.sell_open_dt" class="aC" disabled />
                  </div>
                </td>
                <th>만기평가일</th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field v-model="ds_list.exp_eval_dt" class="aC" disabled />
                  </div>
                </td>
              </tr>
              <tr>
                <th>펀드등급</th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="w200" v-model="ds_list.fund_prop_grade" disabled />
                  </div>
                </td>
                <th>파생등급</th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="w150" v-model="ds_list.fund_deriv_grade" disabled />
                  </div>
                </td>
                <th>판매사이동</th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field v-model="ds_list.fund_sales_move_yn" class="aC" disabled />
                  </div>
                </td>
                <th>대금지급일</th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field v-model="ds_list.amt_paym_dt" class="aC" disabled />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ur-box-container>

      <ur-box-container direction="column" alignV="start">
        <div class="wrap-table-title row">
          <h2>매매정보</h2>
          <div class="wrap-table-badge row">
            <span class="badge-title" v-if="ds_list.int_tr_yn != ''">인터넷거래 가능여부</span>
            <mo-badge class="badge-type impossible" v-if="ds_list.int_tr_yn != ''">
              {{ds_list.int_tr_yn}}
            </mo-badge>
            <span class="desc" v-if="ds_list.day_appl_tp != ''">영업일기준: {{ds_list.day_appl_tp}}</span>
          </div>
        </div>
        <div class="wrap-table">
          <table class="table col-type type02 pl10">
            <tbody>
              <tr>
                <th>매매기준가</th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field mask="number" class="w204" v-model="ds_list.stdprc" disabled /> 원
                  </div>
                </td>
                <th colspan="2" class="text-center th-bg-gray">{{ds_list.late_trading_time}} 前</th>
                <th colspan="2" class="text-center th-border-left th-bg-gray">{{ds_list.late_trading_time}} 後</th>
              </tr>
              <tr>
                <th>과표기준가</th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field mask="number" class="w204" v-model="ds_list.tax_stdprc" disabled /> 원
                  </div>
                </td>
                <th>매수결제일</th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field v-model="ds_list.buy_match_ndays" class="aC" disabled />
                    <mo-text-field v-model="ds_list2.buy_plan_dt" class="aC" disabled />
                  </div>
                </td>
                <th>매수결제일</th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field v-model="ds_list.buy_match_ndays2" class="aC" disabled />
                    <mo-text-field v-model="ds_list2.buy_plan_dt2" class="aC" disabled />
                  </div>
                </td>
              </tr>
              <tr>
                <th>모집시작일</th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="w100p aC" v-model="ds_list.coll_start_dt" disabled />
                  </div>
                </td>
                <th>환매적용일</th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field v-model="ds_list.redem_match_ndays" class="aC" disabled />
                    <mo-text-field v-model="ds_list2.redem_appl_std_dt" class="aC" disabled />
                  </div>
                </td>
                <th>환매적용일</th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field v-model="ds_list.redem_match_ndays2" class="aC" disabled />
                    <mo-text-field v-model="ds_list2.redem_appl_std_dt2" class="aC" disabled />
                  </div>
                </td>
              </tr>
              <tr>
                <th>모집종료일</th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="w100p aC" v-model="ds_list.coll_end_dt" disabled />
                  </div>
                </td>
                <th>환매결제일</th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field v-model="ds_list.redem_setl_ndays" class="aC" disabled />
                    <mo-text-field v-model="ds_list2.redem_setl_plan_dt" class="aC" disabled />
                  </div>
                </td>
                <th>환매결제일</th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field v-model="ds_list.redem_setl_ndays2" class="aC" disabled />
                    <mo-text-field v-model="ds_list2.redem_setl_plan_dt2" class="aC" disabled />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ur-box-container>

      <ur-box-container direction="column" alignV="start">
        <div class="row gap20 w100p">
          <div class="left w50p column">
            <div class="wrap-table-title row">
              <h2>거래가능여부</h2>
            </div>
            <div class="wrap-table">
              <table class="table row-type">
                <thead>
                  <th>신규</th>
                  <th>매입</th>
                  <th>환매</th>
                  <th>인터넷거래</th>
                </thead>
                <tbody>
                  <tr>
                    <td> <mo-text-field v-model="ds_list.open_poss_yn" class="aC" disabled /> </td>
                    <td> <mo-text-field v-model="ds_list.recp_poss_yn" class="aC" disabled /> </td>
                    <td> <mo-text-field v-model="ds_list.paym_poss_yn" class="aC" disabled /> </td>
                    <td> <mo-text-field v-model="ds_list.int_tr_yn" class="aC" disabled /> </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="right w50p column">
            <div class="wrap-table-title row">
              <h2>저축방법구분</h2>
            </div>
            <div class="wrap-table">
              <table class="table row-type">
                <thead>
                  <th>임의식</th>
                  <th>거치식</th>
                  <th>적립식</th>
                </thead>
                <tbody>
                  <tr>
                    <td> <mo-text-field v-model="ds_list.opt_poss_yn" class="aC" disabled /> </td>
                    <td> <mo-text-field v-model="ds_list.def_poss_yn" class="aC" disabled /> </td>
                    <td> <mo-text-field v-model="ds_list.accum_poss_yn" class="aC" disabled /> </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </ur-box-container>

      <ur-box-container direction="column" alignV="start">
        <div class="row gap20 w100p">
          <div class="left w50p column">
            <div class="wrap-table-title row">
              <h2>저축자거래가능여부</h2>
            </div>
            <div class="wrap-table">
              <table class="table row-type">
                <thead>
                  <th>개인</th>
                  <th>과세법인</th>
                  <th>비과세법인</th>
                  <th>비거주자</th>
                </thead>
                <tbody>
                  <tr>
                    <td> <mo-text-field v-model="ds_list.pers_poss_yn" class="aC" disabled /> </td>
                    <td> <mo-text-field v-model="ds_list.taxcorp_poss_yn" class="aC" disabled /> </td>
                    <td> <mo-text-field v-model="ds_list.taxfree_poss_yn" class="aC" disabled /> </td>
                    <td> <mo-text-field v-model="ds_list.non_resi_poss_yn" class="aC" disabled /> </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="right w50p column">
            <div class="wrap-table-title row">
              <h2>수익률정보</h2>
            </div>
            <div class="wrap-table">
              <table class="table row-type">
                <thead>
                  <th>1주일</th>
                  <th>1개월</th>
                  <th>3개월</th>
                  <th>6개월</th>
                  <th>1년</th>
                </thead>
                <tbody>
                  <tr>
                    <td> <mo-text-field v-model="ds_list.w1_yield_rt" class="input-text-right" disabled /> </td>
                    <td> <mo-text-field v-model="ds_list.m1_yield_rt" class="input-text-right" disabled /> </td>
                    <td> <mo-text-field v-model="ds_list.m3_yield_rt" class="input-text-right" disabled /> </td>
                    <td> <mo-text-field v-model="ds_list.m6_yield_rt" class="input-text-right" disabled /> </td>
                    <td> <mo-text-field v-model="ds_list.y1_yield_rt" class="input-text-right" disabled /> </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </ur-box-container>

      <ur-box-container direction="column" alignV="start">
        <div class="row gap20 w100p">
          <div class="left w50p column">
            <div class="wrap-table-title row">
              <h2>정기지급형정보</h2>
            </div>
            <div class="wrap-table">
              <table class="table row-type">
                <thead>
                  <th>가능여부</th>
                  <th>최소값</th>
                  <th>최대값</th>
                  <th>표준</th>
                </thead>
                <tbody>
                  <tr>
                    <td> <mo-text-field v-model="ds_list.pay_yn" class="aC" disabled /> </td>
                    <td> <mo-text-field v-model="ds_list.pay_rate_from" class="input-text-right" disabled /> </td>
                    <td> <mo-text-field v-model="ds_list.pay_rate_to" class="input-text-right" disabled /> </td>
                    <td> <mo-text-field v-model="ds_list.std_pay_rate" class="input-text-right" disabled /> </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="right w50p row gap20">
            <div class="column">
              <div class="wrap-table-title row">
                <h2>보수정보(단위: %)</h2>
              </div>
              <div class="wrap-table">
                <table class="table row-type">
                  <thead>
                    <th>판매수수료</th>
                    <th>보수전체</th>
                    <th>판매사</th>
                    <th>운용사 등</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td> <mo-text-field v-model="ds_list.sell_fee_rt" class="input-text-right" disabled /> </td>
                      <td> <mo-text-field v-model="ds_list.tot_rewd_rt" class="input-text-right" disabled /> </td>
                      <td> <mo-text-field v-model="ds_list.sales_rewd_rt" class="input-text-right" disabled /> </td>
                      <td> <mo-text-field v-model="ds_list.etc_rewd_rt" class="input-text-right" disabled /> </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="column w92">
              <div class="wrap-table-title row">
                <h2 class="mr0">클래스정보</h2>
              </div>
              <div class="wrap-table">
                <table class="table row-type">
                  <thead>
                    <th>클래스구분</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td> <mo-text-field v-model="ds_list.fnd_class_sc_cd" disabled /> </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </ur-box-container>

      <ur-box-container direction="column" alignV="start">
        <div class="wrap-table-title row">
          <h2>환매수수료정보</h2>
        </div>
        <div class="wrap-table">
          <table class="table row-type">
            <thead>
              <th>시작시간</th>
              <th>종료시간</th>
              <th>징수기준1</th>
              <th>환매수수료1</th>
              <th>징수기준2</th>
              <th>환매수수료2</th>
              <th>환입수수료율</th>
            </thead>
            <tbody v-if="ds_list1.length > 0">
              <tr v-for="(item, idx) in ds_list1" :key="idx" >
                <td>{{item.from_value_nm}}</td>
                <td>{{item.to_value_nm}}</td>
                <td class="aR">{{item.levy_std1_nm}}</td>
                <td class="aR">{{item.redem_fee_rt1_nm}}</td>
                <td class="aR">{{item.levy_std2_nm}}</td>
                <td class="aR">{{item.redem_fee_rt2_nm}}</td>
                <td class="aR">{{item.reassign_fee_rt_nm}}</td>
              </tr>
              <!-- <tr>
                <td>1일 이상</td>
                <td>365일 이하</td>
                <td class="text-right"> </td>
                <td class="text-right"> </td>
                <td class="text-right"> </td>
                <td class="text-right"> </td>
                <td class="text-right"> </td>
              </tr>
              <tr>
                <td>1일 이상</td>
                <td>365일 이하</td>
                <td class="text-right"> </td>
                <td class="text-right"> </td>
                <td class="text-right"> </td>
                <td class="text-right"> </td>
                <td class="text-right"> </td>
              </tr> -->
            </tbody>
          </table>
        </div>
      </ur-box-container>

      <ur-box-container direction="column" alignV="start">
        <div class="row align-start gap20 w100p">
          <div class="left wCalc100p-340 column">
            <div class="wrap-table-title row">
              <h2>세제상품유형</h2>
            </div>
            <div class="wrap-table">
              <table class="table">
                <tbody>
                  <tr>
                    <td>
                      <div class="wrap-radiolist2">
                        <mo-radio v-model="chk_tax_7" class="radio-type-check-02" disabled>연금저축</mo-radio>
                        <mo-radio v-model="chk_tax_6" class="radio-type-check-02" disabled>장기주택</mo-radio>
                        <mo-radio v-model="chk_tax_8" class="radio-type-check-02" disabled>장기주식형</mo-radio>
                        <mo-radio v-model="chk_tax_9" class="radio-type-check-02" disabled>회사채형</mo-radio>
                        <mo-radio v-model="chk_tax_3" class="radio-type-check-02" disabled>생계형</mo-radio>
                        <mo-radio v-model="chk_tax_2" class="radio-type-check-02" disabled>세금우대</mo-radio>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="right w340 column">
            <div class="wrap-table-title row flex-sb">
              <h2>최소가입금액</h2>
              <span class="unit">단위: 원</span>
            </div>
            <div class="wrap-table">
              <table class="table row-type">
                <thead>
                  <th>임의식</th>
                  <th>거치식</th>
                  <th>적립식</th>
                </thead>
                <tbody>
                  <tr>
                    <td> <mo-text-field v-model="ds_list.opt_open_minamt" mask="number" disabled /> </td>
                    <td> <mo-text-field v-model="ds_list.accum_open_minamt" mask="number" disabled /> </td>
                    <td> <mo-text-field v-model="ds_list.def_open_minamt" mask="number" disabled /> </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </ur-box-container>

    </main>
    <!-- //.fts-main -->
    <!-- popup308 -->
    <msp-fs-310p
      ref="p310"
      @fs-alert-popup="fn_AlertPopup"
      @fs-popup-callback="fn_callBack300"
      :popupObj="popupObj310"
    ></msp-fs-310p>
    <!-- alert modal popup -->
    <fs-alert-popup
      ref="alertPopup"
      :popupObj="pAlertPopupObj"
    ></fs-alert-popup>

  </ur-page-container>
</template>
<script>
/***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역
***********************************************************************************/
import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'
import FSServiceUtil from '~/src/ui/fs/comm/FSServiceUtil'
import FSHeader from '~/src/ui/fs/comm/FSHeader' // header 영역 (공통)
import FSAlertPopup from '~/src/ui/fs/comm/FSAlertPopup' // Alert 팝업 (공통)
import FSInfoUtil from '~/src/ui/fs/comm/FSInfoUtil'
import MSPFS310P from '~/src/ui/fs/MSPFS310P' // 고객정보 팝업

export default {
  /***********************************************************************************
     * Vue 파일 속성 정의 영역
  ***********************************************************************************/
  name: 'MSPFS201M',
  screenId: 'MSPFS201M',
  components: {
    'fs-header': FSHeader,
    'msp-fs-310p': MSPFS310P,
    'fs-alert-popup': FSAlertPopup
  },
  /***********************************************************************************
     * Life Cycle 함수 정의 영역
  ***********************************************************************************/
  created () {
    this.fn_searchList()
  },
  mounted () {
    this.$bizUtil.insSrnLog('MSPFS201M')
  },
  /***********************************************************************************
     * 화면변수 선언 영역
  ***********************************************************************************/
  data () {
    return {
      // 공통 객체
      pHeaderObj: {
        title: '펀드기본정보조회',
        step: ''
      },
      // 팝업 객체
      popupObj310: {},
      pAlertPopupObj: {},

      // 펀드코드 조회
      ds_search: {
        fndcd: '', // 펀드코드
        fndnm: '', // 펀드명
        std_dt: ''

      },
      ds_list: {
        accum_open_minamt: '', // accum open minamt, Length : 10
        accum_poss_yn: '', // accum poss 여부, Length : 1
        amt_paym_dt: '', // amt paym 일자, Length : 8
        appl_std_dt: '', // appl std 일자, Length : 8 --------------------------------------------?
        buy_match_ndays: '', // buy match ndays, Length : 2
        buy_match_ndays2: '', // buy match ndays2, Length : 2
        coll_end_dt: '', // coll 종료일자, Length : 8
        coll_gb: '', // coll 구분, Length : 4
        coll_start_dt: '', // coll 시작일자, Length : 8 //모집시작일
        currency_cd: '', // 통화코드, Length : 10  ------------------------------------------------?
        day_appl_tp: '', // 일 appl tp, Length : 2
        def_open_minamt: '', // def open minamt, Length : 10
        def_poss_yn: '', // def poss 여부, Length : 1
        etc_rewd_rt: '', // etc rewd rt, Length : 10
        exp_eval_dt: '', // exp 평가일자, Length : 8
        fee_gb: '', // 수수료 구분, Length : 4 ----------------------------------------------------?
        fee_levy_time_gb: '', // 수수료 levy time 구분, Length : 4 --------------------------------?
        fnd_class_sc_cd: '', // fnd class sc 코드, Length : 2

        fund_cd: '', // 펀드 cd, Length : 12 ------------------------------------?
        fund_deriv_grade: '', // 펀드 deriv grade, Length : 40
        fund_exp_dt: '', // 펀드 exp dt, Length : 8
        fund_nm: '', // 펀드 nm, Length : 300
        fund_orign_setdt: '', // 펀드 orign setdt, Length : 8
        fund_prop_grade: '', // 펀드 prop 등급, Length : 30
        fund_sales_move_yn: '', // 펀드 sales move 여부, Length : 1
        fund_state: '', // 펀드 state, Length : 10
        fund_tp3: '', // 펀드 tp3, Length : 1
        fund_tp6: '', // 펀드 tp6, Length : 10
        int_tr_yn: '', // int tr 여부, Length : 1 //매매여부 인터넷거래가능여부
        invst_cmp_purch_rt: '', // 투자 cmp purch rt, Length : 10  투자신탁회사 취득률 ---------------?
        invst_comp_nm: '', // 투자 comp nm, 운용사, Length : 80
        late_trading_time: '', // late trading time = 매매정보 전후 시간대, Length : 10
        levy_std1: '', // levy std1, Length : 40 징수기준1 ------------------------------------------?
        levy_std2: '', // levy std2, Length : 40 징수기준2 ------------------------------------------?
        lt_tm: '', // lt tm, Length : 10 -------------------------------------------------?
        m1_yield_rt: '', // m1 yield rt, Length : 10
        m3_yield_rt: '', // m3 yield rt, Length : 10
        m6_yield_rt: '', // m6 yield rt, Length : 10
        non_resi_poss_yn: '', // non resi poss 여부, 저축자거래가능여부/비거주자 Length : 1
        non_resi_tax_stdprc: '', // non resi tax stdprc, Length : 16
        open_poss_yn: '', // open poss 여부, Length : 1 ----------------------------------------?
        opt_open_minamt: '', // opt open minamt, Length : 10
        opt_poss_yn: '', // opt poss 여부, Length : 1
        pay_rate_from: '', // pay rate from, Length : 10
        pay_rate_to: '', // pay rate to, Length : 10
        pay_yn: '', // pay 여부, Length : 1
        paym_poss_yn: '', // paym poss 여부, Length : 1
        pers_poss_yn: '', // pers poss 여부, Length : 1
        post_fee_appl_mm: '', // post fee appl mm, Length : 80 ----------------------------------?
        post_fee_appl_yn: '', // post fee appl 여부, Length : 1 -----------------------------------?
        reassign_fee_rt: '', // reassign fee rt, Length : 10 환입
        recp_poss_yn: '', // recp poss 여부, Length : 1
        red_fee_appl_gb: '', // red fee appl 구분, Length : 1 ------------------------------------?
        redem_fee_rt1: '', // redem fee rt1, Length : 10 환매수수료1 ------------------------------?
        redem_fee_rt2: '', // redem fee rt2, Length : 10 환매수수료2 -----------------------------?
        redem_match_ndays: '', // redem match ndays, Length : 2
        redem_match_ndays2: '', // redem match ndays2, Length : 2
        redem_setl_ndays: '', // redem setl ndays, Length : 2
        redem_setl_ndays2: '', // redem setl ndays2, Length : 2
        reg_dt: '', // 등록일, Length : 26 ------------------------------------------?
        sales_rewd_rt: '', // 보수정보 - 판매사, Length : 10
        save_tp: '', // save tp, Length : 10 --------------------------------------?
        sell_fee_rt: '', // 보수정보 - 판매수수료 , Length : 10
        sell_open_dt: '', // 판매개시일 , Length : 8
        seq_no: '', // 일련번호, Length : 18 --------------------------------------?
        std_pay_rate: '', // 정기지급형정보 - 표준 , Length : 40
        stdprc: '', // 매매기준가, Length : 16
        tax_2: '', // 세제상품유형, Length : 15
        tax_3: '', // 세제상품유형, Length : 15
        tax_6: '', // 세제상품유형, Length : 20
        tax_7: '', // 세제상품유형, Length : 20
        tax_8: '', // 세제상품유형, Length : 20
        tax_9: '', // 세제상품유형, Length : 20
        tax_stdprc: '', // 과표기준가, Length : 16
        taxcorp_poss_yn: '', // 저축자거래가능여부 - 과세법인 , Length : 1
        taxfree_poss_yn: '', // 저축자거래가능여부 - 비과세법인, Length : 1
        from_std_appl_gb: '', // 시작 std appl 구분, Length : 4 ------------------------------------?
        from_term_gb: '', // 시작 term gb, Length : 1------------------------------------?
        from_value: '', // 시작 value, Length : 10 ------------------------------------?
        to_std_appl_gb: '', // 종료기준적용 구분, Length : 4 ---------------------?
        to_term_gb: '', // 종료기간 구분, Length : 4 -----------------------------?
        to_value: '', // 종료기간 값, Length : 10 ----------------------------------?
        tot_rewd_rt: '', // 보수정보 - 보수전체, Length : 10
        w1_yield_rt: '', // w1 yield rt, Length : 10
        y1_yield_rt: '' // y1 yield rt, Length : 10
      },
      ds_list1: {
        appl_std_dt: '', // 적용기준일자, Length : 8
        currency_cd: '', // 통화코드, Length : 10
        currency_cd_nm: '', // 통화코드, Length : 10
        dec_place_cnt: '', // 소숫점이하자리수, Length : 10
        fee_gb: '', // 수수료구분, Length : 4
        fee_gb1: '', // 수수료구분 1, Length : 1
        from_std_appl_gb: '', // 시작기준적용구분, Length : 4
        from_term_gb: '', // 시작기간구분, Length : 1
        from_value: '', // 시작기간, Length : 8
        from_value_nm: '', // 시작기간, Length : 8
        fund_cd: '', // 펀드코드, Length : 12
        invst_cmp_purch_rt: '', // 투자신탁회사 취득률, Length : 10
        invst_cmp_purch_rt_nm: '', // 투자신탁회사 취득률, Length : 80
        levy_std1: '', // 징수기준1, Length : 40
        levy_std1_nm: '', // 징수기준1, Length : 80
        levy_std2: '', // 징수기준2, Length : 40
        levy_std2_nm: '', // 징수기준2, Length : 80
        post_fee_term_mm: '', // 이연수수료대상기간, Length : 8
        prog_std_dt: '', // 경과조치기준일, Length : 8
        reassign_fee_rt: '', // 환입, Length : 10
        reassign_fee_rt_nm: '', // 환입, Length : 80
        red_fee_appl_gb: '', // 적용조건, Length : 1
        red_fee_appl_gb_nm: '', // 적용조건, Length : 80
        redem_fee_rt1: '', // 환매수수료1, Length : 10
        redem_fee_rt1_nm: '', // 환매수수료1, Length : 80
        redem_fee_rt2: '', // 환매수수료2, Length : 10
        redem_fee_rt2_nm: '', // 환매수수료2, Length : 80
        save_tp: '', // 저축유형, Length : 10
        seq_no: '', // 일련번호, Length : 18
        to_std_appl_gb: '', // 종료기준적용구분, Length : 4
        to_term_gb: '', // 종료기간구분, Length : 4
        to_value: '', // 종료기간, Length : 8
        to_value_nm: '' // 종료기간, Length : 8
      }, // 환매수수료 정보
      ds_list2: {
        buy_plan_dt: '', // 매입결제예정일, Length : 8
        buy_plan_dt2: '', // 매입결제예정일, Length : 8
        redem_appl_std_dt: '', // 환매체결예정일, Length : 8
        redem_appl_std_dt2: '', // 환매체결예정일, Length : 8
        redem_setl_plan_dt: '', // 환매결제예정일, Length : 8
        redem_setl_plan_dt2: '' // 환매결제예정일, Length : 8
      },

      // 세제상품유형
      chk_tax_2: false,
      chk_tax_3: false,
      chk_tax_6: false,
      chk_tax_7: false,
      chk_tax_8: false,
      chk_tax_9: false,

      checked_tax: '',

      // EAI(PO) 호출 객체
      eaiCommObj: FSCommUtil.gfn_eaiCommObj(),

      // 펀드명 칸 넓이 변경 여부
      isIncrease: false
    }
  },
  /***********************************************************************************
     * Computed 함수 정의 영역
     ***********************************************************************************/
  computed: {
    // 세제상품 유형
    rdo_chk_tax () {
      let rtn = []
      rtn.push({value: '7', text: '연금저축'})
      rtn.push({value: '6', text: '장기주택'})
      rtn.push({value: '8', text: '장기주식형'})
      rtn.push({value: '9', text: '회사채형'})
      rtn.push({value: '3', text: '생계형'})
      rtn.push({value: '2', text: '세금우대'})
      return rtn
    }
  },
  /***********************************************************************************
     * watch 함수 정의 영역
     ***********************************************************************************/
  watch: {

  },
  /***********************************************************************************
     * 사용자 함수 정의 영역
     ***********************************************************************************/
  methods: {
    fn_afterFormOnload () {
      // 초기화
      this.fn_init()
      var objParam = this.gfn_getFrameAguments('FORM_PARAM')
      if (!this.gfn_isNull(objParam)) {
        if (objParam.rmvl_srn_id === 'RP260000' && FSCommUtil.gfn_length(objParam.bfcer_fnd_cd)) {
          this.ds_search.fndcd = objParam.bfcer_fnd_cd
          this.ds_search.fndnm = objParam.bfcer_fnd_nm
        }
      }
    },

    /***************************************************************************************
       초기화
      *****************************************************************************************/
    fn_init () {
      this.ds_search = {
        fndcd: '', // 펀드코드
        std_dt: ''
      }
      this.ds_list = {
        accum_open_minamt: '', // accum open minamt, Length : 10
        accum_poss_yn: '', // accum poss 여부, Length : 1
        amt_paym_dt: '', // amt paym 일자, Length : 8
        appl_std_dt: '', // appl std 일자, Length : 8 --------------------------------------------?
        buy_match_ndays: '', // buy match ndays, Length : 2
        buy_match_ndays2: '', // buy match ndays2, Length : 2
        coll_end_dt: '', // coll 종료일자, Length : 8
        coll_gb: '', // coll 구분, Length : 4
        coll_start_dt: '', // coll 시작일자, Length : 8 //모집시작일
        currency_cd: '', // 통화코드, Length : 10  ------------------------------------------------?
        day_appl_tp: '', // 일 appl tp, Length : 2
        def_open_minamt: '', // def open minamt, Length : 10
        def_poss_yn: '', // def poss 여부, Length : 1
        etc_rewd_rt: '', // etc rewd rt, Length : 10
        exp_eval_dt: '', // exp 평가일자, Length : 8
        fee_gb: '', // 수수료 구분, Length : 4 ----------------------------------------------------?
        fee_levy_time_gb: '', // 수수료 levy time 구분, Length : 4 --------------------------------?
        fnd_class_sc_cd: '', // fnd class sc 코드, Length : 2

        fund_cd: '', // 펀드 cd, Length : 12 ------------------------------------?
        fund_deriv_grade: '', // 펀드 deriv grade, Length : 40
        fund_exp_dt: '', // 펀드 exp dt, Length : 8
        fund_nm: '', // 펀드 nm, Length : 300
        fund_orign_setdt: '', // 펀드 orign setdt, Length : 8
        fund_prop_grade: '', // 펀드 prop 등급, Length : 30
        fund_sales_move_yn: '', // 펀드 sales move 여부, Length : 1
        fund_state: '', // 펀드 state, Length : 10
        fund_tp3: '', // 펀드 tp3, Length : 1
        fund_tp6: '', // 펀드 tp6, Length : 10
        int_tr_yn: '', // int tr 여부, Length : 1 //매매여부 인터넷거래가능여부
        invst_cmp_purch_rt: '', // 투자 cmp purch rt, Length : 10  투자신탁회사 취득률 ---------------?
        invst_comp_nm: '', // 투자 comp nm, 운용사, Length : 80
        late_trading_time: '', // late trading time = 매매정보 전후 시간대, Length : 10
        levy_std1: '', // levy std1, Length : 40 징수기준1 ------------------------------------------?
        levy_std2: '', // levy std2, Length : 40 징수기준2 ------------------------------------------?
        lt_tm: '', // lt tm, Length : 10 -------------------------------------------------?
        m1_yield_rt: '', // m1 yield rt, Length : 10
        m3_yield_rt: '', // m3 yield rt, Length : 10
        m6_yield_rt: '', // m6 yield rt, Length : 10
        non_resi_poss_yn: '', // non resi poss 여부, 저축자거래가능여부/비거주자 Length : 1
        non_resi_tax_stdprc: '', // non resi tax stdprc, Length : 16
        open_poss_yn: '', // open poss 여부, Length : 1 ----------------------------------------?
        opt_open_minamt: '', // opt open minamt, Length : 10
        opt_poss_yn: '', // opt poss 여부, Length : 1
        pay_rate_from: '', // pay rate from, Length : 10
        pay_rate_to: '', // pay rate to, Length : 10
        pay_yn: '', // pay 여부, Length : 1
        paym_poss_yn: '', // paym poss 여부, Length : 1
        pers_poss_yn: '', // pers poss 여부, Length : 1
        post_fee_appl_mm: '', // post fee appl mm, Length : 80 ----------------------------------?
        post_fee_appl_yn: '', // post fee appl 여부, Length : 1 -----------------------------------?
        reassign_fee_rt: '', // reassign fee rt, Length : 10 환입
        recp_poss_yn: '', // recp poss 여부, Length : 1
        red_fee_appl_gb: '', // red fee appl 구분, Length : 1 ------------------------------------?
        redem_fee_rt1: '', // redem fee rt1, Length : 10 환매수수료1 ------------------------------?
        redem_fee_rt2: '', // redem fee rt2, Length : 10 환매수수료2 -----------------------------?
        redem_match_ndays: '', // redem match ndays, Length : 2
        redem_match_ndays2: '', // redem match ndays2, Length : 2
        redem_setl_ndays: '', // redem setl ndays, Length : 2
        redem_setl_ndays2: '', // redem setl ndays2, Length : 2
        reg_dt: '', // 등록일, Length : 26 ------------------------------------------?
        sales_rewd_rt: '', // 보수정보 - 판매사, Length : 10
        save_tp: '', // save tp, Length : 10 --------------------------------------?
        sell_fee_rt: '', // 보수정보 - 판매수수료 , Length : 10
        sell_open_dt: '', // 판매개시일 , Length : 8
        seq_no: '', // 일련번호, Length : 18 --------------------------------------?
        std_pay_rate: '', // 정기지급형정보 - 표준 , Length : 40
        stdprc: '', // 매매기준가, Length : 16
        tax_2: '', // 세제상품유형, Length : 15
        tax_3: '', // 세제상품유형, Length : 15
        tax_6: '', // 세제상품유형, Length : 20
        tax_7: '', // 세제상품유형, Length : 20
        tax_8: '', // 세제상품유형, Length : 20
        tax_9: '', // 세제상품유형, Length : 20
        tax_stdprc: '', // 과표기준가, Length : 16
        taxcorp_poss_yn: '', // 저축자거래가능여부 - 과세법인 , Length : 1
        taxfree_poss_yn: '', // 저축자거래가능여부 - 비과세법인, Length : 1
        from_std_appl_gb: '', // 시작 std appl 구분, Length : 4 ------------------------------------?
        from_term_gb: '', // 시작 term gb, Length : 1------------------------------------?
        from_value: '', // 시작 value, Length : 10 ------------------------------------?
        to_std_appl_gb: '', // 종료기준적용 구분, Length : 4 ---------------------?
        to_term_gb: '', // 종료기간 구분, Length : 4 -----------------------------?
        to_value: '', // 종료기간 값, Length : 10 ----------------------------------?
        tot_rewd_rt: '', // 보수정보 - 보수전체, Length : 10
        w1_yield_rt: '', // w1 yield rt, Length : 10
        y1_yield_rt: '' // y1 yield rt, Length : 10
      }
      this.ds_list1 = {
        appl_std_dt: '', // 적용기준일자, Length : 8
        currency_cd: '', // 통화코드, Length : 10
        currency_cd_nm: '', // 통화코드, Length : 10
        dec_place_cnt: '', // 소숫점이하자리수, Length : 10
        fee_gb: '', // 수수료구분, Length : 4
        fee_gb1: '', // 수수료구분 1, Length : 1
        from_std_appl_gb: '', // 시작기준적용구분, Length : 4
        from_term_gb: '', // 시작기간구분, Length : 1
        from_value: '', // 시작기간, Length : 8
        from_value_nm: '', // 시작기간, Length : 8
        fund_cd: '', // 펀드코드, Length : 12
        invst_cmp_purch_rt: '', // 투자신탁회사 취득률, Length : 10
        invst_cmp_purch_rt_nm: '', // 투자신탁회사 취득률, Length : 80
        levy_std1: '', // 징수기준1, Length : 40
        levy_std1_nm: '', // 징수기준1, Length : 80
        levy_std2: '', // 징수기준2, Length : 40
        levy_std2_nm: '', // 징수기준2, Length : 80
        post_fee_term_mm: '', // 이연수수료대상기간, Length : 8
        prog_std_dt: '', // 경과조치기준일, Length : 8
        reassign_fee_rt: '', // 환입, Length : 10
        reassign_fee_rt_nm: '', // 환입, Length : 80
        red_fee_appl_gb: '', // 적용조건, Length : 1
        red_fee_appl_gb_nm: '', // 적용조건, Length : 80
        redem_fee_rt1: '', // 환매수수료1, Length : 10
        redem_fee_rt1_nm: '', // 환매수수료1, Length : 80
        redem_fee_rt2: '', // 환매수수료2, Length : 10
        redem_fee_rt2_nm: '', // 환매수수료2, Length : 80
        save_tp: '', // 저축유형, Length : 10
        seq_no: '', // 일련번호, Length : 18
        to_std_appl_gb: '', // 종료기준적용구분, Length : 4
        to_term_gb: '', // 종료기간구분, Length : 4
        to_value: '', // 종료기간, Length : 8
        to_value_nm: '' // 종료기간, Length : 8
      } // 환매수수료 정보

      this.ds_list2 = {
        buy_plan_dt: '', // 매입결제예정일, Length : 8
        buy_plan_dt2: '', // 매입결제예정일, Length : 8
        redem_appl_std_dt: '', // 환매체결예정일, Length : 8
        redem_appl_std_dt2: '', // 환매체결예정일, Length : 8
        redem_setl_plan_dt: '', // 환매결제예정일, Length : 8
        redem_setl_plan_dt2: '' // 환매결제예정일, Length : 8
      }

      // 세제상품유형
      this.chk_tax_2 = false
      this.chk_tax_3 = false
      this.chk_tax_6 = false
      this.chk_tax_7 = false
      this.chk_tax_8 = false
      this.chk_tax_9 = false
    },

    /***************************************************************************************
       * Function명 : fn_callBack
       * 설명       : 조회
      *****************************************************************************************/
    fn_searchList () {
      if (FSCommUtil.gfn_isNull(this.ds_search.fndcd)) {
        return
      }
      // var sOutDatasets =
      //  "ds_list=outSI466500Search1 "
      //   + "ds_list1=outSI466500Search2 "
      //   + "ds_list2=outSI466500Search3";

      this.eaiCommObj.lv_vm = this
      // this.eaiCommObj.trnstId = 'txTSSFS46S1'
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570174_S'
      this.eaiCommObj.params = {
        fund_cd: this.ds_search.fndcd,
        std_dt: ''
      }

      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack)
    },

    /***************************************************************************************
       * Function명 : fn_callBack
       * 설명       : CALLBACK
      *****************************************************************************************/
    fn_callBack (result) {
      console.log('펀드기본정보조회 콜백 ', result)
      this.ds_list = []
      this.ds_list1 = []
      this.ds_list2 = []

      if (result.data.error_msg === '809900') {
        var data = result.data.si466500_O_00VO
        var data1 = result.data.si466500_O_01VO
        var data2 = result.data.si466500_O_02VO

        this.ds_list = data[0]
        if (data1.length > 0) {
          this.ds_list1 = data1
        }
        this.ds_list2 = data2[0]

        this.chk_tax_2 = data[0].tax_2 === '1'
        this.chk_tax_3 = data[0].tax_3 === '1'
        this.chk_tax_6 = data[0].tax_6 === '1'
        this.chk_tax_7 = data[0].tax_7 === '1'
        this.chk_tax_8 = data[0].tax_8 === '1'
        this.chk_tax_9 = data[0].tax_9 === '1'
        // var fnlDsgnEmnoNm01 = this.ds_list.coll_gb;
        // this.inter_yn.set_enable(true); //인터넷거래 가능여부
        // this.class_gb.set_enable(true); //클래스 구분

        // 펀드 설정일
        this.ds_list.fund_orign_setdt = FSCommUtil.gfn_dateReplace(1, this.ds_list.fund_orign_setdt)
        this.ds_list.fund_exp_dt = FSCommUtil.gfn_dateReplace(1, this.ds_list.fund_exp_dt) // 펀드 만기일
        this.ds_list.sell_open_dt = FSCommUtil.gfn_dateReplace(1, this.ds_list.sell_open_dt) // 판매개시일
        this.ds_list.exp_eval_dt = FSCommUtil.gfn_dateReplace(1, this.ds_list.exp_eval_dt) // 만기평가일
        this.ds_list.amt_paym_dt = FSCommUtil.gfn_dateReplace(1, this.ds_list.amt_paym_dt) // 대금지급일
        this.ds_list.coll_start_dt = FSCommUtil.gfn_dateReplace(1, this.ds_list.coll_start_dt) // 모집시작일
        this.ds_list.coll_end_dt = FSCommUtil.gfn_dateReplace(1, this.ds_list.coll_end_dt) // 모집종료일
        this.ds_list2.buy_plan_dt = FSCommUtil.gfn_dateReplace(1, this.ds_list2.buy_plan_dt) // 매수결제일
        this.ds_list2.buy_plan_dt2 = FSCommUtil.gfn_dateReplace(1, this.ds_list2.buy_plan_dt2) // 매수결제일
        this.ds_list2.redem_appl_std_dt = FSCommUtil.gfn_dateReplace(1, this.ds_list2.redem_appl_std_dt) // 환매적용일
        this.ds_list2.redem_appl_std_dt2 = FSCommUtil.gfn_dateReplace(1, this.ds_list2.redem_appl_std_dt2) // 환매적용일
        this.ds_list2.redem_setl_plan_dt = FSCommUtil.gfn_dateReplace(1, this.ds_list2.redem_setl_plan_dt) // 환매결제일
        this.ds_list2.redem_setl_plan_dt2 = FSCommUtil.gfn_dateReplace(1, this.ds_list2.redem_setl_plan_dt2) // 환매결제일

        this.ds_list.stdprc = (this.ds_list.stdprc === 0) ? '0.00' : this.ds_list.stdprc // 매매기준가
        this.ds_list.tax_stdprc = (this.ds_list.tax_stdprc === 0) ? '0.00' : this.ds_list.tax_stdprc // 과표기준가

        this.ds_list.w1_yield_rt = FSCommUtil.gfn_numFormat("#,##0.00", this.ds_list.w1_yield_rt)//(Math.round(Number(this.ds_list.w1_yield_rt)*100)/100).toFixed(2) // 수익률정보
        this.ds_list.m1_yield_rt = FSCommUtil.gfn_numFormat("#,##0.00", this.ds_list.m1_yield_rt)
        this.ds_list.m3_yield_rt = FSCommUtil.gfn_numFormat("#,##0.00", this.ds_list.m3_yield_rt)
        this.ds_list.m6_yield_rt = FSCommUtil.gfn_numFormat("#,##0.00", this.ds_list.m6_yield_rt)
        this.ds_list.y1_yield_rt = FSCommUtil.gfn_numFormat("#,##0.00", this.ds_list.y1_yield_rt)
      } else if (result.body == null) {
        let t_popupObj = {content: '펀드정보가 존재하지 않습니다.'}
        this.fn_AlertPopup(0, t_popupObj)
      } else {
        let t_popupObj = {content: '펀드정보가 존재하지 않습니다.'}
        this.fn_AlertPopup(0, t_popupObj)
      }
    },

    /***********************************************************************************
       * Function명 : fn_fnd_cd_onchar
       * 설명       : 펀드코드 keyin (onchar)
       ***********************************************************************************/
    fn_fnd_cd_onchar () {
      var fndcd = this.ds_search.fndcd
      if (FSCommUtil.gfn_length(fndcd) < 6) {
        this.ds_search.fndnm = ''
        return
      }
      FSInfoUtil.gfn_fund_cd_search(this, fndcd, this.fn_fndCallBack, '1')
    },

    fn_fndCallBack (response) {
      console.log('펀드코드조회 콜백', response)
      if (FSCommUtil.gfn_length(response.data.sy100100_O_00VO) > 0) {
        var data = response.data.sy100100_O_00VO[0]
        this.ds_search.fndcd = data.bfcer_fnd_cd
        this.ds_search.fndnm = data.bfcer_fnd_nm
      } else {
        this.ds_search.fndnm = ''
        let t_popupObj = {content: '입력한 펀드코드가 존재하지 않습니다.'}
        this.fn_AlertPopup(0, t_popupObj)
      }
    },

    /******************************************************************************
       * Function명 : fn_OpenPopup
       * 설명       : popup 컴포넌트 호출
       ******************************************************************************/
    fn_OpenPopup () {
      this.$refs.p310.fn_Open()
    },
    /******************************************************************************
       * Function명 : fn_callBack300
       * 설명       : popup 컴포넌트에서 callback
       ******************************************************************************/
    fn_callBack300 (type, pData) {
      console.log('팝업콜백', pData)

      this.ds_search.fndcd = pData.bfcer_fnd_cd
      this.fn_fnd_cd_onchar()
    },
    /******************************************************************************
       * Function명 : fn_AlertPopup
       * 설명       : alert popup 호출(공통)
       ******************************************************************************/
    fn_AlertPopup (type, pPopupObj) {
      if ( !FSCommUtil.gfn_isNull(pPopupObj) ) {
        this.pAlertPopupObj.content = pPopupObj.content
        this.pAlertPopupObj.confirm = false
        this.pAlertPopupObj.cancel = true

        if ( !pPopupObj.cancel ) {
          this.pAlertPopupObj.cancel = pPopupObj.cancel
        }
        if ( pPopupObj.confirm ) {
          this.pAlertPopupObj.confirm = pPopupObj.confirm
          this.pAlertPopupObj.confirmFunc = pPopupObj.confirmFunc
          this.pAlertPopupObj.confirmData = pPopupObj.confirmData
        }
        if ( pPopupObj.closeFunc ) {
          this.pAlertPopupObj.closeFunc = pPopupObj.closeFunc
          this.pAlertPopupObj.closeData = pPopupObj.closeData
        }
      }
      this.$refs.alertPopup.fn_Open()
    },

    // 펀드명 적용
    inputIncrease () {
      this.isIncrease = !this.isIncrease
    }
  }

}
</script>
<style scoped>
</style>
